
import { Component, Prop, Vue } from "vue-property-decorator";
import Notify from "@/utils/notifications";
import DeletePopover from "@/components/DeletePopover.vue";
import {
  EventRule,
  Rule,
  findRules,
  postEventRule,
  patchEventRule,
  deleteEventRule,
} from "@/api/witheredHeath";
import _ from "underscore";

@Component({
  components: {
    DeletePopover,
  },
})
export default class RuleForm extends Vue {
  @Prop() eventId!: number;
  @Prop() eventRules!: Array<EventRule>;
  @Prop() show!: boolean;

  loading = false;
  rules: Array<Rule> = [];
  eventRule = {} as EventRule;
  create = false;
  popoverVisible = false;
  getRules() {
    findRules({
      sort: "+active,+name",
    })
      .then(res => {
        this.rules = res.rows;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  addRule() {
    if (this.validateLastRule()) {
      this.create = true;
      this.eventRules.push({} as EventRule);
    } else Notify.warning("Todos los campos son requeridos.");
  }

  saveEventRules() {
    if (this.validateLastRule()) {
      this.loading = true;
      const newRules = this.eventRules.filter(
        eventRule =>
          Object.prototype.hasOwnProperty.call(eventRule, "event-rule-id") ===
            false || eventRule["event-rule-id"] === undefined
      );
      Promise.all(
        newRules.map(eventRule => postEventRule(this.eventId, eventRule))
      )
        .then(() => {
          Notify.successful("Reglas actualizadas exitosamente.");
          this.loading = false;
        })
        .catch(error => {
          Notify.gebServerError(error);
          this.loading = false;
        });

      const rulesToUpdate = this.eventRules.filter(
        eventRule => eventRule["event-rule-id"]
      );
      Promise.all(
        rulesToUpdate.map(eventRule =>
          patchEventRule(eventRule["event-rule-id"], eventRule)
        )
      )
        .then(() => {
          Notify.successful("Reglas actualizadas exitosamente.");
          this.loading = false;
        })
        .catch(error => {
          Notify.gebServerError(error);
          this.loading = false;
        });
    }
  }

  removeEventRule(id: number) {
    this.loading = true;
    if (id)
      deleteEventRule(id)
        .then(() => {
          Notify.successful("Regla removida exitosamente.");
          this.loading = false;
        })
        .catch(error => {
          Notify.gebServerError(error);
          this.loading = false;
        })
        .finally(() => {
          this.closeDrawer();
        });
  }

  validateLastRule() {
    const length = this.eventRules.length;
    if (length == 0) return true;

    const lastRule = this.eventRules[length - 1];
    if (lastRule["rule-id"] && lastRule["report-column-name"]) return true;
    return false;
  }

  closeDrawer() {
    this.$emit("closeDrawer", false);
  }

  get showDrawer() {
    return this.show;
  }

  mounted() {
    this.getRules();
  }
}
