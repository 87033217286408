
import { Component, Vue } from "vue-property-decorator";
import DeletePopover from "@/components/DeletePopover.vue";
import Notify from "@/utils/notifications";
import AppsDrawer from "@/components/apps/TheAppsDrawer.vue";
import {
  findApps,
  findEvents,
  deleteEvent,
  findEventRules,
  App,
  Events,
  EventRule,
} from "@/api/witheredHeath";
import EventDrawer from "@/components/apps/TheEventDrawer.vue";
import EventRuleDrawer from "@/components/apps/TheEventRuleDrawer.vue";
import _ from "underscore";

interface RankingSystem {
  id: number;
  ranking: number;
}

@Component({
  components: {
    AppsDrawer,
    EventDrawer,
    DeletePopover,
    EventRuleDrawer,
  },
})
export default class Setting extends Vue {
  fullscreenLoading = true;
  create = false;
  appsLoading = false;
  appsDrawer = false;
  app: App = {} as App;
  apps: Array<App> = [];
  appsActive: Array<App> = [];
  eventLoading = false;
  eventDrawer = false;
  eventRuleDrawer = false;
  event: Events = {} as Events;
  eventRule: EventRule = {} as EventRule;
  events: Array<Events> = [];
  eventRules: Array<EventRule> = [];
  eventId = 0;

  getApps() {
    this.appsLoading = true;
    findApps({
      sort: "-active,+name",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.apps = res.rows;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.appsLoading = false;
      });
  }

  getAppsActive() {
    this.appsLoading = true;
    findApps({
      sort: "+name",
      active: 1,
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.appsActive = res.rows;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.appsLoading = false;
      });
  }

  getEvents() {
    this.eventLoading = true;
    findEvents({
      sort: "+name",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.events = res.rows;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.eventLoading = false;
      });
  }

  getEventRules(eventId: number) {
    this.eventLoading = true;
    findEventRules(eventId)
      .then(res => {
        this.fullscreenLoading = false;
        this.eventRules = res.rows.map((row, index) => ({
          ...row,
          "rule-id": row.rule.id,
        }));
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.eventLoading = false;
      });
  }

  deleteEvent(id: number) {
    this.eventLoading = true;
    deleteEvent(id)
      .then(res => {
        this.getEvents();
        Notify.successful(`Evento eliminado exitosamente.`);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.eventLoading = false;
      });
  }

  newDrawer(type: string) {
    this.create = true;
    switch (type) {
      case "apps":
        this.app = {} as App;
        this.appsDrawer = true;
        break;
      case "event":
        this.event = {} as Events;
        this.eventDrawer = true;
        this.getAppsActive();
        break;
    }
  }

  editDrawer(index: number, type: string) {
    this.create = false;
    switch (type) {
      case "apps":
        this.app = this.apps[index];
        this.appsDrawer = true;
        break;
      case "event":
        this.event = this.events[index];
        this.eventDrawer = true;
        this.getAppsActive();
        break;
    }
  }

  configDrawer(id: number) {
    this.eventId = id;
    this.getEventRules(id);
    this.eventRuleDrawer = true;
  }

  closeDrawer(value: boolean) {
    this.appsDrawer = value;
    this.eventDrawer = value;
    this.eventRuleDrawer = value;
  }

  created() {
    const index = "4.1";
    const title = "Ajustes de Apps Operaciones";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: title,
      },
    ]);
    this.getApps();
    this.getEvents();
  }
}
