
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  Platform,
  findPlatforms,
  App,
  postApp,
  patchApp,
} from "@/api/witheredHeath";
import Notify from "@/utils/notifications";

@Component
export default class AppsDrawer extends Vue {
  @Prop() app!: App;
  @Prop() create!: boolean;
  @Prop() show!: boolean;

  loading = false;
  rules = {
    name: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        min: 3,
        message: "Debe contener al menos 3 caracteres.",
      },
    ],
    "external-app-id": [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        min: 3,
        message: "Debe contener al menos 3 caracteres.",
      },
    ],
    "external-sheet-id": [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        min: 3,
        message: "Debe contener al menos 3 caracteres.",
      },
    ],
    "platform-id": [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
  };

  platforms: Array<Platform> = [];

  getPlatforms() {
    findPlatforms({
      sort: "+active,+name",
    })
      .then(res => {
        this.platforms = res.rows;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  createApp() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        postApp(this.app)
          .then(() => {
            this.$emit("newApp");
            Notify.successful("App creada exitosamente.");
          })
          .catch(error => {
            Notify.gebServerError(error);
          })
          .finally(() => {
            this.closeDrawer();
          });
      }
    });
  }

  updateApp() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        if (this.app && this.app.id)
          patchApp(this.app.id, this.app)
            .then(() => {
              Notify.successful("App actualizada exitosamente.");
            })
            .catch(error => {
              Notify.gebServerError(error);
            })
            .finally(() => {
              this.closeDrawer();
            });
      }
    });
  }

  closeDrawer() {
    this.loading = false;
    (this.$refs["form"] as HTMLFormElement).clearValidate();
    this.$emit("closeDrawer", false);
  }

  get title() {
    if (this.create) {
      return "Crear nueva app";
    } else {
      if (this.app.platform) {
        this.app["platform-id"] = this.app.platform.id;
      }
      return "Actualizar app";
    }
  }

  get showDrawer() {
    return this.show;
  }
  created() {
    this.getPlatforms();
  }
}
