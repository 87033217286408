import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export default class WitheredHeathClient {
  private static instance: WitheredHeathClient;
  private http: AxiosInstance;
  private constructor() {
    this.http = axios.create({
      baseURL: process.env.VUE_APP_WITHERED_HEATH_URL,
    });
  }

  public static getInstance(): WitheredHeathClient {
    if (!WitheredHeathClient.instance) {
      WitheredHeathClient.instance = new WitheredHeathClient();
    }
    return WitheredHeathClient.instance;
  }

  async find(url: string, config?: AxiosRequestConfig | undefined) {
    return await this.http.get(url, config);
  }
  async patch(
    url: string,
    data?: any,
    config?: AxiosRequestConfig | undefined
  ) {
    return await this.http.patch(url, data, config);
  }
  async post(url: string, data: any, config?: AxiosRequestConfig | undefined) {
    return await this.http.post(url, data, config);
  }
  async delete(url: string, config?: AxiosRequestConfig | undefined) {
    return await this.http.delete(url, config);
  }
}
